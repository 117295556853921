import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  documento_nota: '',
  dtemissao: {
    i: null,
    f: null,
  },
  documento_despesa: '',
  enviado: null,
  receita: [],
  recibo: '',
};

export const GnresContext = createContext();

export const GnresProvider = ({ children }) => {
  const [gnres, setGnres] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getGnres = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: gnres?.filter,
        page: gnres?.page,
        size: gnres?.size,
        order: gnres?.order,
        ...payload,
      });
      setGnres((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postUf = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post(`/Fiscal/GNRE/UF`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postGNRE = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      if (data?.ws) {
        await api.post(`/Fiscal/GNRE`, data);
      } else {
        const res = await api.post(`/Fiscal/GNRE`, data, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([res?.data], {
          type: res?.headers['content-type'] || 'application/pdf',
        });

        const url = window.URL.createObjectURL(blob);
        window.open(url);
        window.URL.revokeObjectURL(url);
      }

      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteGNRE = async ({ params, cb = null }) => {
    try {
      setDeleteLoading(true);

      await api.delete(`/Fiscal/GNRE`, { params });

      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postDespesas = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);

      await api.post(`/Fiscal/GNRE/Despesas`, data);

      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getConsulta = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      await api.get(`/Fiscal/GNRE/Consultar`, { params });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getNotas = async ({ cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Fiscal/GNRE/Notas`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <GnresContext.Provider
      value={{
        gnres,
        getLoading,
        getGnres,
        postLoading,
        postUf,
        postDespesas,
        postGNRE,
        deleteGNRE,
        deleteLoading,
        getConsulta,
        getNotas,
      }}
    >
      {children}
    </GnresContext.Provider>
  );
};
