import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { GnresContext } from 'contexts/GnresContext';
import { useModal } from 'components/Modals';
import { toastWarning } from 'utils/toast';
import ResumoModal from 'components/Modals/ResumoModal';
import ConfigModal from './Modals/ConfigModal';
import DespesaModal from './Modals/DespesaModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Gnre = () => {
  const rotina = 'GNRE';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const {
    gnres,
    getGnres,
    getLoading,
    postLoading,
    postUf,
    postDespesas,
    getConsulta,
    postGNRE,
    deleteGNRE,
  } = useContext(GnresContext);
  const { control, getValues, reset } = useForm({
    defaultValues: gnres?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    closeModal();
    getGnres({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Atualizar configurações da UF',
      icon: 'sync',
      action: () =>
        openModal(
          <ConfigModal onSubmit={(data) => postUf({ data, cb: loadGrid })} />
        ),
    },
    {
      name: 'Gerar despesas',
      icon: 'post_add',
      action: (selected) => {
        if (selected?.length) {
          if (!selected?.some((a) => a?.documento_despesa)) {
            openModal(
              <DespesaModal
                onSubmit={(data) =>
                  postDespesas({
                    data: {
                      ...data,
                      documentos: selected?.map((a) => ({
                        documento_id: a?.documento_id,
                        gnre_receita_id: a?.gnre_receita_id,
                      })),
                    },
                    cb: loadGrid,
                  })
                }
              />
            );
          } else {
            toastWarning('Selecione apenas itens sem despesa gerada');
          }
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Gerar GNRE',
      icon: 'add',
      action: () => navigate('/app/Gnre/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: gnres?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: gnres?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={gnres?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];

  const rowOptions = [
    {
      name: 'Consultar GNRE',
      icon: 'search',
      menu: true,
      show: ({ row }) => row?.enviado === 'SIM',
      action: ({ row }) => {
        if (row?.recibo) {
          const params = { recibo: row?.recibo };
          getConsulta({ params, cb: loadGrid });
        } else {
          const data = {
            gnre_receita_id: row?.gnre_receita_id,
            documentos: [row?.documento_id],
            ws: false,
          };

          postGNRE({ data });
        }
      },
    },
    {
      name: 'Excluir GNRE',
      icon: 'delete',
      menu: true,
      show: ({ row }) => !row?.recibo,
      action: ({ row }) => {
        const params = {
          gnre_receita_id: row?.gnre_receita_id,
          documento_id: row?.documento_id,
        };

        deleteGNRE({ params, cb: loadGrid });
      },
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={gnres?.colunas} />
      <Card>
        <Grid
          grid={gnres}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading || postLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={gnres} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Gnre;
