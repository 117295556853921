export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );
