import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  InputLabel,
  LinearProgress,
  Pagination,
  Switch,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { GnresContext } from 'contexts/GnresContext';
import { toastWarning } from 'utils/toast';
import localeText from 'utils/localeText';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Card from 'components/Card';
import moment from 'moment';

const Gerar = () => {
  const navigate = useNavigate();
  const defaultValues = { tributo_id: null, ws: true };
  const { drops } = useContext(DropsContext);
  const { getLoading, getNotas, postLoading, postGNRE } =
    useContext(GnresContext);
  const [notas, setNotas] = useState([]);
  const [selected, setSelected] = useState([]);
  const { control, watch, setValue, handleSubmit } = useForm({ defaultValues });
  const _notas = notas?.filter((f) =>
    Boolean(watch('tributo_id'))
      ? f?.Tributos?.some((s) => s?.tributo_id === watch('tributo_id'))
      : true
  );

  const onSubmit = (values) => {
    if (!Boolean(values?.tributo_id)) {
      return toastWarning('Tributo não informado');
    }
    if (!Boolean(selected?.length)) {
      return toastWarning('Selecione ao menos um documento');
    }

    const data = {
      ...values,
      documentos: selected?.map((s) => s?.id),
    };

    postGNRE({ data, cb: () => navigate(-1) });
  };

  useEffect(() => {
    getNotas({ cb: setNotas });
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <Container>
      <Header titulo="Gerar GNRE" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Filtros">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="tributo_id"
                  control={control}
                  label="Tributo"
                  options={drops?.Tributo?.filter((a) =>
                    [2, 13, 14]?.includes(a?.value)
                  )}
                  onValueChange={() => setSelected([])}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Switch
                  checked={Boolean(watch('ws'))}
                  onChange={(_, v) => setValue('ws', v)}
                />
                <InputLabel>Usar Web Service</InputLabel>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Notas">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  rows={_notas}
                  columns={[
                    {
                      field: 'id',
                      headerName: 'ID',
                      sortable: false,
                      width: 100,
                    },
                    {
                      field: 'dtemissao',
                      headerName: 'Emissão',
                      sortable: false,
                      width: 150,
                      valueGetter: ({ row }) =>
                        moment(row?.dtemissao || undefined).format(
                          'DD/MM/YYYY'
                        ),
                    },
                    {
                      field: 'documento',
                      headerName: 'Nº do Documento',
                      sortable: false,
                      width: 170,
                    },
                    {
                      field: 'serie',
                      headerName: 'Série',
                      sortable: false,
                      width: 170,
                    },
                    {
                      field: 'cadastro',
                      headerName: 'Cliente',
                      sortable: false,
                      width: 500,
                    },
                    {
                      field: 'uf',
                      headerName: 'UF',
                      sortable: false,
                      width: 100,
                    },
                    {
                      field: 'icms_st',
                      headerName: 'ICMS ST',
                      sortable: false,
                      width: 200,
                      valueGetter: ({ row }) => {
                        const valor =
                          row?.Tributos?.find((m) => m?.tributo_id === 2)
                            ?.valor || 0;

                        return valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        });
                      },
                    },
                    {
                      field: 'fcp',
                      headerName: 'FCP',
                      sortable: false,
                      width: 200,
                      valueGetter: ({ row }) => {
                        const valor =
                          row?.Tributos?.find((m) => m?.tributo_id === 13)
                            ?.valor || 0;

                        return valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        });
                      },
                    },
                    {
                      field: 'difal',
                      headerName: 'DIFAL',
                      sortable: false,
                      width: 200,
                      valueGetter: ({ row }) => {
                        const valor =
                          row?.Tributos?.find((m) => m?.tributo_id === 14)
                            ?.valor || 0;

                        return valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        });
                      },
                    },
                  ]}
                  autoHeight
                  pagination
                  pageSize={20}
                  density="compact"
                  loading={getLoading}
                  disableColumnMenu
                  checkboxSelection
                  showCellRightBorder
                  showColumnRightBorder
                  disableSelectionOnClick
                  localeText={localeText}
                  isRowSelectable={() => Boolean(watch('tributo_id'))}
                  keepNonExistentRowsSelected
                  selectionModel={selected?.map((s) => s?.id)}
                  onSelectionModelChange={(ids) =>
                    setSelected(
                      ids?.map((id) => _notas?.find((f) => f?.id === id))
                    )
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  loading={postLoading}
                >
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Gerar;
