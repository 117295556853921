import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Apuracao from 'views/Apuração';
import Gnre from 'views/Gnre';
import GnreGerar from 'views/Gnre/Gerar';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Apuracao" element={<Apuracao />} />
    <Route path="Gnre" element={<Outlet />}>
      <Route path="" element={<Gnre />} />
      <Route path="Gerar" element={<GnreGerar />} />
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
  </Routes>
);

export default RoutesTree;
