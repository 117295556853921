import { useContext } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { GnresContext } from 'contexts/GnresContext';
import { findOnArray } from 'utils/functions';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const ConfigModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading } = useContext(GnresContext);
  const defaultValues = {
    filial_id: user?.filial_id || null,
    uf_id: null,
    codigo_receita_id: null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onConfirm = (values) => {
    const uf = findOnArray(values?.uf_id, drops?.UF, 'sigla');
    onSubmit({ ...values, uf });
  };

  return (
    <>
      <DialogTitle>Atualizar configurações da UF</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={8}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              name="uf_id"
              control={control}
              label="UF"
              options={drops?.UF}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="codigo_receita_id"
              control={control}
              label="Receita"
              options={drops?.GnreReceita}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onConfirm)}
          loading={postLoading}
          color="primary"
          variant="contained"
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};
export default ConfigModal;
