import { useContext } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import { DropsContext } from 'contexts/DropsContext';
import { GnresContext } from 'contexts/GnresContext';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import moment from 'moment';

const DespesaModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postLoading } = useContext(GnresContext);
  const defaultValues = {
    planoconta_id: null,
    cadastro_id: null,
    dtvenc: moment()?.format('YYYY-MM-DD'),
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <>
      <DialogTitle>Gerar despesas</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Dropdown
              name="planoconta_id"
              control={control}
              label="Plano de contas"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12}>
            <InputMask
              name="dtvenc"
              control={control}
              label="Data de vencimento"
              type="date"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
          color="primary"
          variant="contained"
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};
export default DespesaModal;
