import moment from 'moment';

const mock = [
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
];

export default mock;
