import { Grid } from '@mui/material';
import { dropBoolean } from 'utils/drops';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('documento_nota') && (
        <Grid item xs={12}>
          <Input
            name="documento_nota"
            control={control}
            label="Nº da Nota"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento_despesa') && (
        <Grid item xs={12}>
          <Input
            name="documento_despesa"
            control={control}
            label="Nº da Despesa"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('enviado') && (
        <Grid item xs={12}>
          <Dropdown
            name="enviado"
            control={control}
            label="Enviado"
            options={dropBoolean}
          />
        </Grid>
      )}
      {checkDisplay('receita') && (
        <Grid item xs={12}>
          <Dropdown
            name="receita"
            control={control}
            label="Receita"
            options={drops?.GnreReceita}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('recibo') && (
        <Grid item xs={12}>
          <Input name="recibo" control={control} label="Recibo" type="number" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
